@import '~theme/variables';

.header {
  padding: $space-s;
  color: $color-neutral-02;

  & strong {
    color: $color-neutral-01;
  }
}

.textCenter {
  text-align: center;
}

.list {
  height: 100%;
  overflow: auto;
  padding: 0 $space-s $space-s;
  flex: 1;

  .card {
    border-radius: $border-radius-02;
  }
}

.listItem + .listItem {
  margin-top: 0.8rem;

  @include breakpoint(sm) {
    margin-top: 1.6rem;
  }
}

.mobileOverlay {
  display: none;

  @include breakpoint(sm, max) {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.swipeOverlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: $z-index-above;
  padding: 2.2rem 0 4rem 0rem;
  width: 100%;

  &::before {
    content: '';
    background-color: $color-neutral-04;
    position: absolute;
    top: 0.8rem;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 3.2rem;
    height: 0.4rem;
    border-radius: 10rem;
  }

  @include breakpoint(sm) {
    display: none;
  }
}
