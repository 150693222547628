@import '~theme/variables';

.clubSearchForm {
  position: relative;
  flex: 1;

  &:focus-within .searchInputButton svg {
    fill: $color-primary;
  }
}

.searchInput {
  box-shadow: $box-shadow-01;
  padding-left: $space-s;
  padding-right: $space-xl;
}

.inputButton {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 1.6rem;
  margin: 0 auto;

  & svg {
    width: 2rem;
    height: 2rem;
    fill: $color-neutral-02;
  }
}
